// Input

input.form-control {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	box-shadow: none !important;
	font-size: 14px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	border: 1px solid darken($gray,20) !important;
	&.input-white {
		background-color: $white !important;
	}
	&.input-gray {
		background-color: $gray !important;
	}
	&.search-right {
		padding-right: 40px;
	}
}

.form-group {
	label {
		font-family: $font-family-bold;
		font-size: 14px;
		@include ellipsis();
		width: 100%;
		margin-bottom: 4px;
	}
	&.form-group-input {
		position: relative;
	}
	.image-search-input {
		width: 35px;
		position: absolute;
		top: 2.5px;
		right: 5px;
		cursor: pointer;
	}
}