// Toast

.toast-component {
	position: fixed;
	z-index: 9999;
	bottom: 10px;
	right: 10px;
	min-width: 200px;
	pointer-events: none;
	text-align: center;
}