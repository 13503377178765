.add-button-component {
	text-decoration: none !important;
	color: $white !important;
	display: inline-block;
	& > div {
		width: 200px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		background-color: $white;
		padding: 20px;
		@include shadow2();
		img {
			width: 30px;
			height: 30px;
		}
		p {
			color: $danger;
			font-family: $font-family-bold;
			margin: 0px;
			margin-left: 20px;
		}
	}
}