// Users

#users {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	select {
		@include selectTable();
	}
	.input-search-right {
		@include inputTable();
	}
	.image-switch {
		width: 50px;
		cursor: pointer;
	}
	.name {
		font-family: $font-family-bold;
		margin: 0px;
	}
	.email {
		margin: 0px;
	}
}

#create-user {
	padding-bottom: 60px;
	.container-red-component {
		max-width: 700px;
		.select-image {
			width: 300px;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
}

.modal-view-user {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
	.row-modal-title {
		width: 100%;
		.container-view-user {
			display: flex;
			align-items: center;
			img {
				width: 65px;
				height: 65px;
				margin-left: 10px;
			}
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 18px;
			font-family: $font-family-bold;
			margin-top: 15px;
			margin-left: 10px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
		.link-icon {
			display: inline-block;
			margin-top: 10px;
			margin-right: 0px;
		}
	}
}

#modal-view-user {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 30px;
	button[type="button"] {
		background-color: transparent !important;
		color: $danger !important;
		font-family: $font-family-bold;
		font-size: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.view-user-image {
		max-width: 100%;
	}
	.email-container {
		display: flex;
		margin-bottom: 20px;
		p {
			font-size: 14px;
			margin: 0px;
			margin-top: 5px;
			margin-left: 5px;
		}
		img {
			width: 30px;
			height: 30px;
		}
	}
	.bold {
		margin: 0px;
		font-family: $font-family-bold;
	}
}