// Storage

#storage {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	select {
		@include selectTable();
	}
	.input-search-right {
		@include inputTable();
	}
	.name {
		font-family: $font-family-bold;
		margin: 0px;
	}
	.code {
		margin: 0px;
	}
	.add-button-component {
		& > div {
			width: auto;
			margin-left: 20px;
		}
	}
	.item-container {
		font-family: $font-family-bold;
		color: $danger;
	}
	.icon-delete-container {
		width: 30px;
		height: 30px;
		margin-left: 10px;
	}
	.change-status {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		cursor: pointer;
		width: 150px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active {
			background-color: $green;
		}
		&.inactive {
			background-color: $prod-gray;
		}
	}
}

.modal-create-storage {
	.modal-header {
		display: none;
	}
	.modal-body {
		background-color: transparent;
	}
	.modal-dialog {
		max-width: 800px;
	}
}

#storage-warehouse {
	padding-bottom: 60px;
	.container-red-component {
		max-width: 700px;
		.select-image {
			width: 300px;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	button[type="button"] {
		font-family: $font-family-bold;
		background-color: transparent !important;
		color: $black !important;
		font-size: 18px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
		margin-top: 20px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
}

#create-storage {
	padding-bottom: 60px;
	.container-red-component {
		max-width: 700px;
		.select-image {
			width: 300px;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.btn-cancel {
		font-family: $font-family-bold;
		background-color: transparent !important;
		color: $black !important;
		font-size: 18px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.input-group {
		background-color: $gray;
		border-radius: 10px;
		overflow: hidden;
		height: 50px;
		.input-group-prepend {
			height: 50px;
			width: 50px;
		}
		.form-group {
			width: calc(100% - 50px)
		}
		input {
			border-radius: 0px !important;
		}
		img {
			width: 40px;
			height: 40px;
			margin-top: 5px;
			margin-left: 5px;
		}
	}
	.container-title-level {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	h3 {
		color: $danger;
		font-family: $font-family-bold;
		font-size: 23px;
		margin-bottom: 0px;
	}
	.add-button {
		background-color: $danger !important;
		min-width: auto;
		width: 45px;
		height: 45px;
		border-radius: 30px;
		margin-left: 20px;
		@media (max-width: 991px) {
			margin-top: 0px;
			margin-bottom: 20px;
		}
		img {
			width: 18px;
			height: 18px;
		}
	}
	.remove-button {
		background-color: transparent !important;
		min-width: auto;
		width: 60px;
		height: 60px;
		border-radius: 30px;
		margin-top: 23px;
		@media (max-width: 991px) {
			margin-top: 0px;
			margin-bottom: 20px;
		}
		img {
			width: 45px;
			height: 45px;
		}
	}
	.container-title-red {
		display: flex;
		i.fa {
			color: $white;
			font-size: 22px;
			position: absolute;
			right: 20px;
		}
	}
}

.modal-view-storage {
	@include modal();
	.modal-dialog {
		max-width: 400px;
	}
	.row-modal-title {
		width: 100%;
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 18px;
			font-family: $font-family-bold;
			margin-top: 15px;
			margin-left: 10px;
			margin-bottom: 15px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
		.link-icon {
			display: inline-block;
			margin-top: 10px;
			margin-right: 0px;
		}
	}
}

#modal-view-storage {
	button[type="button"] {
		background-color: transparent !important;
		color: $danger !important;
		font-family: $font-family-bold;
		font-size: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	h3 {
		color: $danger;
		font-family: $font-family-bold;
		font-size: 16px;
		width: 90%;
		margin: 0 auto;
		margin-top: 20px;
	}
	p.code {
		color: $danger;
		font-family: $font-family-bold;
	}
	.row-items {
		margin: 0px !important;
		width: 90%;
		margin: 0 auto !important;
		p {
			margin-top: 15px;
		}
		.col-6 {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}
	hr {
		width: 95%;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	p.name {
		font-size: 15px;
		width: 90%;
		margin: 0 auto;
		margin-top: 15px;
	}
	p.description {
		width: 90%;
		margin: 0 auto;
		margin-top: 30px;
	}
}

#storage-design {
	padding: 30px;
	@media (max-width: 600px) {
		display: none;
	}
	.container-black {
		padding: 30px;
		background-color: $black2;
		border-radius: 10px;
	}
	select {
		@include selectTable();
	}
	.row-selects {
		max-width: 800px;
		margin: 0 auto;
	}
	.containers-scroll {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		flex-direction: row;
		border-bottom: 1px solid $white;
		padding-bottom: 10px;
		padding-top: 10px;
		.item-scroll {
			padding: 10px;
			padding-right: 20px;
			padding-left: 20px;
			cursor: pointer;
			position: relative;
			width: 150px;
			box-shadow: none !important;
			outline: 0px !important;
			border: 0px !important;
			border-right: 1px solid $white !important;
			background-color: transparent !important;
			&.active {
				background-color: $danger !important;
			}
			.container-shapes {
				background-color: $white;
				position: absolute;
				@include shadow2();
				border-radius: 10px;
				padding: 10px;
				left: 50%;
				top: 50%;
				z-index: 10;
				width: 150px;
				.container-shape {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.cuadrado {
					width: 50px;
					min-height: 50px;
					background-color: $default;
					margin-top: 10px;
				}
				.rectangulo {
					width: 50px;
					height: 20px;
					background-color: $default;
					margin-top: 10px;
				}
				.redondeado {
					width: 50px;
					min-height: 50px;
					border-radius: 5px;
					background-color: $default;
					margin-top: 10px;
				}
				.ovalo {
					width: 40px;
					min-height: 50px;
					border-radius: 50px;
					background-color: $default;
					margin-top: 10px;
				}
			}
			p {
				margin: 0px;
				font-size: 12px;
				color: $white;
				@include noSelect();
				overflow-wrap: break-word;
			}
			.container-text {
				display: inline-flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	.icons-container {
		border-radius: 5px;
		background-color: $default2;
		position: absolute;
		right: -50px;
		top: 0px;
		z-index: 9;
		.icon-item {
			padding: 5px;
			border-bottom: 1px solid $default;
			cursor: pointer;
			&:first-child {
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
			}
			&:hover {
				background-color: lighten($default2,5);
			}
			&.last-item {
				border-bottom: 0px;
				border-bottom-right-radius: 5px;
				border-bottom-left-radius: 5px;
			}
		}
		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
		}
	}
	.container-design {
		width: 350px;
		height: 600px;
		margin: 0 auto;
		margin-top: 30px;
		background-color: $white;
		position: relative;
		.colors-container {
			width: 130px;
			background-color: $white;
			border-radius: 10px;
			border: 1px solid $black;
			padding: 10px;
			position: absolute;
			left: 50px;
			top: 10px;
			.row {
				margin: 0px;
			}
			.col-3 {
				padding: 0px;
			}
			.item-color {
				width: 20px;
				height: 20px;
				border-radius: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				cursor: pointer;
			}
		}
		.container-rotation {
			.container-shapes {
				background-color: $white;
				position: absolute;
				@include shadow2();
				border-radius: 10px;
				padding: 10px;
				left: 50px;
				top: 0px;
				width: 150px;
				.row, .col-6 {
					padding: 0px;
					margin: 0px;
				}
				.container-shape {
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
				}
				.cuadrado {
					width: 30px;
					height: 30px;
					background-color: $default;
					margin-top: 10px;
					cursor: pointer;
				}
				.rectangulo {
					width: 30px;
					height: 20px;
					background-color: $default;
					margin-top: 10px;
					cursor: pointer;
				}
				.redondeado {
					width: 30px;
					height: 30px;
					border-radius: 5px;
					background-color: $default;
					margin-top: 10px;
					cursor: pointer;
				}
				.ovalo {
					width: 25px;
					height: 30px;
					border-radius: 50px;
					background-color: $default;
					margin-top: 10px;
					cursor: pointer;
				}
			}
		}
		.container-item {
			padding: 10px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border-color: 4px;
			border: 4px solid $green;
			@include noSelect();
			&.cuadrado {
				width: 120px;
				height: 120px;
				overflow: hidden;
			}
			&.rectangulo {
				width: 120px;
				height: 50px;
				overflow: hidden;
			}
			&.redondeado {
				width: 120px;
				height: 120px;
				border-radius: 20px;
				overflow: hidden;
			}
			&.ovalo {
				width: 100px;
				height: 120px;
				border-radius: 50px;
				overflow: hidden;
			}
			p {
				margin: 0px;
				text-align: center;
				font-family: $font-family-bold;
				font-size: 12px;
			}
		}
	}
	.focusable-container {
		background-color: transparent !important;
		outline: 0px !important;
		border: 0px !important;
		box-shadow: 0px !important;
		padding: 0px !important;
	}
	button.btn-submit {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		background-color: $danger !important;
		padding-top: 15px !important;
		padding-bottom: 15px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.container-shape-selected {
		width: 35px;
		height: 35px;
		background-color: $default;
		margin-right: 10px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		&.active {
			background-color: $white;
		}
		.cuadrado {
			width: 20px;
			height: 20px;
			background-color: $default;
		}
		.rectangulo {
			width: 20px;
			height: 10px;
			background-color: $default;
		}
		.redondeado {
			width: 20px;
			height: 20px;
			border-radius: 5px;
			background-color: $default;
		}
		.ovalo {
			width: 15px;
			height: 20px;
			border-radius: 50px;
			background-color: $default;
		}
	}
}

#storage-design-message {
	display: none;
	@media (max-width: 600px) {
		display: block;
	}
	p {
		font-size: 20px;
		font-family: $font-family-bold;
		text-align: center;
		width: 90%;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
	}
}