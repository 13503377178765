// Profile

#profile, #password {
	label {
		font-size: 14px;
	}
	input {
		border: 0px !important;
		border-radius: 10px !important;
		background-color: $gray !important;
	}
	h3 {
		font-size: 14px;
		font-family: $font-family-bold;
		margin-bottom: 20px;
		color: $black;
	}
	.select-document {
		p {
			font-family: $font-family-bold;
			margin-bottom: 10px;
			font-size: 14px;
		}
		.container-select-document {
			display: flex;
			select {
				width: 120px;
				margin-right: 10px;
			}
		}
	}
	.container-form {
		border-radius: 10px;
		padding: 20px;
		padding-top: 30px;
		margin: 0px;
	}
	form {
		background-color: $white;
		border-radius: 10px;
		width: 90%;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 20px;
		@include shadow3();
		button[type="submit"] {
			margin-top: 20px;
		}
	}
}

#profile {
	background-color: $gray;
	form {
		padding: 30px;
		padding-top: 10px;
		button {
			border-radius: 10px !important;
			width: 200px;
			font-size: 16px;
			margin: 0 auto;
			margin-top: 10px;
			background-color: $danger !important;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			@include shadow3();
			font-family: $font-family-bold;
		}
		label {
			color: lighten($black,20);
			font-family: $font-family;
			font-size: 16px;
			margin-top: 10px;
		}
		h3 {
			font-size: 18px;
			color: $prod-green;
			font-family: $font-family-bold;
			margin-bottom: 20px;
		}
		h4 {
			color: lighten($black,20);
			font-size: 20px;
			margin-top: 15px;
		}
		p {
			font-size: 16px;
			color: lighten($black,20);
		}
	}
}

#password {
	.container-form {
		border-radius: 10px;
		padding: 20px;
		padding-top: 30px;
	}
	form {
		margin: 0 auto;
		padding: 40px;
	}
	ul {
		margin-left: 40px;
		list-style: none;
		padding: 0px;
		padding-left: 10px;
		font-size: 14px;
		margin-bottom: 40px;
	}
	.important {
		margin-top: 20px;
		margin-left: 40px;
		color: $white;
		background-color: $danger;
		display: inline-block;
		text-transform: uppercase;
		border-radius: 20px;
		padding: 5px;
		padding-right: 20px;
		padding-left: 20px;
		font-size: 14px;
	}
	button {
		border-radius: 10px !important;
		width: 200px;
		font-size: 16px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: $danger !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
}