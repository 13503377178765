// Modal

.modal {
	outline: 0px !important;
	.modal-dialog {
		outline: 0px !important;
	}
	.modal-title {
		font-family: $font-family-bold;
		color: $black;
		font-size: 15px !important;
	}
	.modal-content {
		background-color: transparent !important;
		border-color: transparent !important;
	}
	.modal-header {
		border-bottom: 0px !important;
		padding-bottom: 0px !important;
		background-color: $prod-gray;
		border-top-right-radius: 2px !important;
		border-top-left-radius: 2px !important;
		button.close {
			outline: 0px !important;
			color: $black !important;
			margin-right: 0px;
			span {
				font-size: 30px !important;
			}			
		}
	}
	.modal-body {
		background-color: $prod-gray;
		border-bottom-right-radius: 2px !important;
		border-bottom-left-radius: 2px !important;
	}
}