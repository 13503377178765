// Providers

#providers {
	max-width: 1100px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.name {
		font-family: $font-family-bold;
		margin: 0px;
	}
	.email {
		margin: 0px;
	}
	.change-status {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		cursor: pointer;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active {
			background-color: $green;
		}
		&.inactive {
			background-color: $prod-gray;
		}
	}
}

#create-provider {
	max-width: 950px;
	margin: 0 auto;
	padding-bottom: 30px;
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.container-datepicker {
		@include datepickerTable();
		background-color: $danger !important;
		&, .placeholder {
			color: $white;
		}
	}
	label {
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}
	.input-group {
		background-color: $gray;
		border-radius: 10px;
		overflow: hidden;
		height: 50px;
		&.rif-container {
			background-color: $white;
			border-radius: 0px;
			.input-group-prepend {
				width: 60px;
				.form-group {
					width: 100%;
					margin-left: 0px;
				}
				select {
					border-radius: 10px !important;
					height: 50px;
				}
			}
			.form-group {
				width: calc(100% - 70px);
				margin-left: 10px;
				input {
					border-radius: 10px !important;
				}
			}
		}
		.input-group-prepend {
			height: 50px;
			width: 50px;
		}
		.form-group {
			width: calc(100% - 50px)
		}
		input {
			border-radius: 0px !important;
		}
		img {
			width: 40px;
			height: 40px;
			margin-top: 5px;
			margin-left: 5px;
		}
	}
}

.modal-view-provider {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
	.row-modal-title {
		width: 100%;
		.container-view-provider {
			display: flex;
			align-items: center;
			img {
				width: 65px;
				height: 65px;
				margin-left: 10px;
			}
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 18px;
			font-family: $font-family-bold;
			margin-top: 15px;
			margin-left: 10px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
		.link-icon {
			display: inline-block;
			margin-top: 10px;
			margin-right: 0px;
		}
	}
}

#modal-view-provider {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 30px;
	button[type="button"] {
		background-color: transparent !important;
		color: $danger !important;
		font-family: $font-family-bold;
		font-size: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.change-status {
		display: inline-block;
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		width: 120px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active {
			background-color: $green;
		}
		&.inactive {
			background-color: $prod-gray;
		}
	}
	.bold {
		font-family: $font-family-bold;
		margin-top: 20px;
		margin-bottom: 0px;
	}
	.item-image {
		display: flex;
		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
			margin-left: 15px;
		}
		&.bold {
			font-family: $font-family-bold;
		}
		&.red {
			img {
				width: 20px;
				height: 20px;
				margin-left: 18px;
			}
			margin-top: 20px;
			color: $danger;
			font-family: $font-family-bold;
		}
		& > div {
			p {
				margin: 0px;
			}
		}
	}
}