// Paperclip

.container-paper-clip {
	button {
		color: $black !important;
		text-align: left !important;
		padding-left: 50px;
		border: 1px solid $gray !important;
		background-color: $white !important;
		position: relative;
		height: 40px;
		white-space: nowrap;
		overflow: hidden;
		@include ellipsis();
		.container-icon-paperclip {
			background-color: $prod-green;
			position: absolute;
			width: 40px;
			left: 0px;
			height: 38px;
			top: 0px;
			border-bottom-left-radius: 5px !important;
			border-top-left-radius: 5px !important;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 20px;
			}
			i.fa {
				color: $white;
			}
		}
	}
}