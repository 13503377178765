// Title

.container-title-component {
	background-color: $danger;
	padding: 10px;
	border-radius: 15px;
	width: 90%;
	margin: 0 auto;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-bottom: -70px;
	margin-top: 30px;
	position: relative;
	z-index: 99;
	.title-component {
		width: 100%;
		font-size: 16px;
		font-family: $font-family-bold;
		color: $white;
		margin: 0px;
	}
}