// Departments

#departments {
	max-width: 700px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	table {
		.name {
			font-size: 16px;
			font-family: $font-family-bold;
		}
	}
}

.modal-create-department {
	@include modal();
	.modal-dialog {
		max-width: 370px;
	}
}

#modal-create-department {
	.content-form {
		padding: 20px;
		label {
			font-family: $font-family-bold;
			font-size: 14px;
		}
		input {
			background-color: $gray !important;
			border-radius: 10px !important;
			border: 0px !important;
			height: 50px;
		}
		.input-group {
			background-color: $gray;
			border-radius: 10px;
			overflow: hidden;
			height: 50px;
			.input-group-prepend {
				height: 50px;
				width: 50px;
			}
			.form-group {
				width: calc(100% - 50px)
			}
			input {
				border-radius: 0px !important;
			}
			img {
				width: 40px;
				height: 40px;
				margin-top: 5px;
				margin-left: 5px;
			}
		}
	}
}