// Products

#products {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.image-category {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		object-fit: cover;
	}
}

#create-product {
	max-width: 950px;
	margin: 0 auto;
	padding-bottom: 30px;
	button.btn-submit, button.btn-normal {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	form {
		width: 95%;
		margin: 0 auto;
		margin-top: 25px;
	}
	.row-tabs {
		border-bottom: 1px solid $black;
		width: 95%;
		margin: 0 auto;
		.tab {
			cursor: pointer;
			&.active {
				p {
					color: $danger;
				}
			}
			p {
				margin: 0px;
				font-family: $font-family-bold;
				text-align: center;
			}
		}
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input, select {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.input-group {
		background-color: $gray;
		border-radius: 10px;
		overflow: hidden;
		height: 50px;
		.input-group-prepend {
			height: 50px;
			width: 50px;
		}
		.form-group {
			width: calc(100% - 50px)
		}
		input {
			border-radius: 0px !important;
		}
		img {
			width: 40px;
			height: 40px;
			margin-top: 5px;
			margin-left: 5px;
		}
	}
	.add-button {
		background-color: $danger !important;
		min-width: auto;
		width: 65px;
		height: 65px;
		border-radius: 32.5px;
		margin-left: 20px;
		img {
			width: 25px;
			height: 25px;
		}
	}
	.row-presentation {
		width: 90%;
		margin: 0 auto;
	}
	.item-presentation {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.remove-button {
		background-color: transparent !important;
		min-width: auto;
		width: 60px;
		height: 60px;
		border-radius: 30px;
		margin-top: 23px;
		@media (max-width: 991px) {
			margin-top: 0px;
			margin-bottom: 20px;
		}
		img {
			width: 45px;
			height: 45px;
		}
	}
	.container-paper-clip {
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 45px;
		.container-icon-paperclip {
			display: none;
		}
		button {
			background-color: transparent !important;
			box-shadow: none !important;
			width: auto;
			height: auto;
			border-radius: 100px !important;
			padding: 0px !important;
			margin: 0px !important;
			overflow: visible;
			.selected-image {
				width: 180px;
				height: 180px;
				object-fit: cover;
				border-radius: 100px !important;
				&.inactive {
					border: 2px solid $black
				}
			}
			.circle-red {
				width: 45px;
				height: 45px;
				position: absolute;
				bottom: 0px;
				right: 0px;
			}
		}
	}
}

.modal-view-product {
	.modal-header {
		border-top-right-radius: 10px !important;
		border-top-left-radius: 10px !important;
	}
	.modal-body {
		border-bottom-right-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
	}
	.modal-body, .modal-header {
		background-color: $white;
	}
	.modal-dialog {
		max-width: 800px;
	}
}

#modal-view-product {
	.image-category {
		width: 100%;
		object-fit: contain;
		margin-top: 0px;
		margin-bottom: 40px;
		border-radius: 5px;
	}
	h3 {
		font-size: 20px;
		margin: 0px;
		margin-bottom: 5px;
		color: $danger;
		font-family: $font-family-bold;
	}
	.code {
		margin: 0px;
		margin-top: 5px;
		margin-bottom: 20px;
		color: $prod-gray;
		font-family: $font-family-bold;
	}
	.red {
		color: $danger;
	}
	.bold {
		font-family: $font-family-bold;
		margin: 0px;
	}
	.description {
		margin: 0px;
		font-size: 14px;
	}
	.link-icon {
		display: inline-block;
		margin-top: 30px;
	}
	.stock-title {
		margin-top: 20px;
		color: $prod-gray;
		font-size: 14px;
	}
	.stock {
		font-family: $font-family-bold;
		font-size: 20px;
	}
}