// Download Button

.download-button-component {
	text-decoration: none !important;
	color: $white !important;
	& > div {
		@include gradient();
		display: flex;
		width: 250px;
		border-radius: 10px;
		margin-left: 10px;
		padding: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
		p {
			padding: 0px;
			margin: 0px;
			color: $white;
			margin-left: 10px;
			margin-top: 3px;
		}
		img {
			width: 30px;
			height: 30px;
			margin-left: 10px;
		}
	}
}