// Categories

#categories {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	.input-table {
		@include inputTable();
	}
	select {
		@include selectTable();
	}
	.image-category {
		width: 45px;
		height: 45px;
		border-radius: 5px;
	}
	.item-category {
		margin: 0px;
		margin-top: 10px;
	}
	.item-icons {
		margin-top: 5px;
	}
	.item-category {
		padding-top: 15px;
	}
}

.modal-create-category {
	@include modal();
	.modal-dialog {
		max-width: 600px;
	}
}

.modal-view-category {
	.modal-header {
		border-top-right-radius: 10px !important;
		border-top-left-radius: 10px !important;
	}
	.modal-body {
		border-bottom-right-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
	}
	.modal-body, .modal-header {
		background-color: $white;
	}
	.modal-dialog {
		max-width: 800px;
	}
}

#modal-view-category {
	.image-category {
		width: 100%;
		object-fit: contain;
		margin-top: 0px;
		margin-bottom: 40px;
		border-radius: 5px;
	}
	h3 {
		font-size: 20px;
		margin: 0px;
		margin-bottom: 5px;
		color: $danger;
		font-family: $font-family-bold;
	}
	.code {
		margin: 0px;
		margin-top: 20px;
		margin-bottom: 20px;
		color: $prod-gray;
		font-family: $font-family-bold;
	}
	.bold {
		font-family: $font-family-bold;
		margin: 0px;
	}
	.description {
		margin: 0px;
		font-size: 14px;
	}
	.link-icon {
		display: inline-block;
		margin-top: 30px;
	}
}

#modal-create-category {
	.form-check-inline {
		label {
			margin-bottom: 0px;
		}
	}
	select {
		@include selectTable();
		background-color: $gray !important;
	}
	.form-check-input {
		height: 20px !important;
	}
	.content-form {
		padding: 20px;
		label {
			font-family: $font-family-bold;
			font-size: 14px;
		}
		input {
			background-color: $gray !important;
			border-radius: 10px !important;
			border: 0px !important;
			height: 50px;
		}
		.input-group {
			background-color: $gray;
			border-radius: 10px;
			overflow: hidden;
			height: 50px;
			.input-group-prepend {
				height: 50px;
				width: 50px;
			}
			.form-group {
				width: calc(100% - 50px)
			}
			input {
				border-radius: 0px !important;
			}
			img {
				width: 40px;
				height: 40px;
				margin-top: 5px;
				margin-left: 5px;
			}
		}
	}
	.logo-container {
		@include shadow2();
		display: flex;
		border-radius: 10px;
		padding: 10px;
		background-color: $white;
		margin-bottom: 15px;
		.selected-image {
			border-radius: 5px;
			width: 100px;
			height: 100px;
			object-fit: cover;
		}
		.container-paper-clip {
			.container-icon-paperclip {
				display: none;
			}
			button {
				background-color: transparent !important;
				padding: 5px;
				font-size: 18px;
				font-family: $font-family-bold;
				color: $danger !important;
				border: 0px !important;
				margin-left: 40px;
				margin-top: 30px;
			}
		}
	}
	textarea {
		box-shadow: none !important;
		background-color: $gray !important;
		border-radius: 10px;
	}
}