// Operations

#operations {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.name {
		font-family: $font-family-medium;
		color: $danger;
	}
}

#create-operation {
	max-width: 700px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-white {
		padding-left: 0px;
		padding-right: 0px;
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.row-numbers {
		border-top: 1px solid $prod-gray;
		margin-top: 15px;
	}
	.input-container {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $prod-gray;
		cursor: pointer;
		padding-top: 10px;
		padding-bottom: 10px;
		.circle {
			width: 20px;
			height: 20px;
			display: inline-block;
			border-radius: 10px;
			background-color: $gray;
			margin-right: 15px;
			margin-left: 15px;
			margin-top: 5px;
			margin-bottom: 5px;
			border: 1px solid $prod-gray;
			&.active {
				background-color: $green;
			}
		}
		p {
			margin: 0px;
			margin-right: 10px;
			font-size: 16px;
			font-family: $font-family-bold;
		}
	}
	.input-table {
		@include inputTable();
		.input-white {
			background-color: $gray !important;
		}
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
}