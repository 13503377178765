// Orders

#orders {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.code {
		color: $danger;
		font-family: $font-family-bold;
	}
	.deleted-item {
		td {
			font-style: italic;
			color: lighten($prod-gray,20);
		}
	}
	.status-container {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active {
			background-color: $green;
		}
		&.inactive {
			background-color: $white;
			p {
				color: $black;
			}
		}
		&.incomplete {
			background-color: $danger;
		}
		&.deleted {
			background-color: $danger;
			p {
				color: $white;
			}
		}
	}
}

#create-order {
	max-width: 95%;
	margin: 0 auto;
	padding-bottom: 30px;
	.image-search-input {
		width: 35px;
		height: 35px;
		top: 41px;
	}
	.btn-create {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	form {
		background-color: $white;
		@include shadow2();
		border-radius: 20px;
		padding: 20px;
		width: 95%;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.comments-container {
		background-color: $white;
		@include shadow2();
		border-radius: 20px;
		padding: 20px;
		width: 95%;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.container-select-search {
		.form-control, .css-1hwfws3 {
			height: 55px;
		}
		.css-yk16xz-control, .css-1pahdxg-control {
			background-color: $gray !important;
			border: 0px !important;
			height: 55px;
			border-radius: 15px !important;
		}
	}
	.address {
		p {
			margin-bottom: 0px;
			display: inline-block;
		}
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}
	.bold {
		margin-bottom: 0px;
		font-family: $font-family-bold;
	}
	.code {
		font-size: 13px;
	}
	.date {
		font-size: 14px;
		font-family: $font-family-bold;
		margin-top: 10px;
		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}
	table {
		tr {
			border-top: 1px solid $black;
			&:last-child {
				border-bottom: 1px solid $black;
			}
			th, td {
				font-size: 14px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	.comments {
		input {
			background-color: transparent !important;
			border-radius: 0px !important;
		}
		border-bottom: 1px solid $black !important;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.row-date {
		max-width: 350px;
		p.name {
			font-size: 14px;
			margin: 0px;
			margin-top: 8px;
		}
		.container-datepicker {
			background-color: $danger !important;
			border-radius: 10px;
			border: 0px !important;
			color: $white !important;
			.placeholder {
				color: $white;
			}
		}
	}
	.trash-icon {
		img {
			width: 40px;
			height: 40px;
		}
	}
}

.modal-delete-order {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
	.modal-body {
		padding-top: 0px !important;
	}
	.modal-header {
		display: none;
	}
}

#modal-delete-order {
	padding: 20px;
	.confirm-image {
		width: 110px;
		height: 110px;
	}
	p {
		text-align: center;
		margin-top: 20px;
	}
	textarea {
		box-shadow: none !important;
		background-color: $gray !important;
		border-radius: 10px;
	}
	button.btn-submit {
		border-radius: 10px !important;
		width: 200px;
		font-size: 15px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.close-modal-view {
		font-size: 25px;
		color: $black !important;
		display: inline-block;
	}
}

.modal-view-observation {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 10px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 16px;
			font-family: $font-family-bold;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-left: 20px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
	}
}

.modal-view-order {
	@include modal();
	.modal-dialog {
		max-width: 600px;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 20px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 18px;
			font-family: $font-family-bold;
			margin-top: 15px;
			margin-left: 10px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
	}
}

#modal-view-order {
	padding: 25px;
	.date {
		font-size: 14px;
		font-family: $font-family-bold;
		color: $danger;
		margin-top: 10px;
		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}
	}
	.container-provider {
		margin-bottom: 20px;
		.bold {
			font-family: $font-family-bold;
		}
		font-size: 14px;
	}
	table {
		tr {
			border-top: 1px solid $black;
			&:last-child {
				border-bottom: 1px solid $black;
			}
			th, td {
				font-size: 13px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	.comments {
		input {
			background-color: transparent !important;
			border-radius: 0px !important;
		}
		border-bottom: 1px solid $black !important;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.row-date {
		max-width: 350px;
		margin-top: 20px;
		p.name {
			font-size: 14px;
			margin: 0px;
			margin-top: 8px;
		}
		.container-datepicker {
			background-color: $danger !important;
			border-radius: 10px;
			border: 0px !important;
			color: $white !important;
			.placeholder {
				color: $white;
			}
		}
	}
	.address {
		p {
			margin-bottom: 0px;
			display: inline-block;
		}
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}
	.bold {
		margin-bottom: 0px;
	}
}

#modal-view-observation {
	padding: 20px;
}