// Recipes

#recipes {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.name {
		font-family: $font-family-medium;
		color: $danger;
	}
}

#create-recipe {
	// max-width: 1000px;
	width: 90%;
	margin: 0 auto;
	padding-bottom: 40px;
	.product-percentage .input-white {
		background-color: $gray !important;
		border: 0px !important;
		border-radius: 10px !important;
		width: 100px;
	}
	.product-percentage {
		.form-group {
			margin: 0px;
		}
	}
	.col-white {
		padding: 0px;
		background-color: $white;
		.input-table {
			.input-white {
				background-color: $gray !important;
			}
		}
		table {
			thead {
				tr {
					border-bottom: 1px solid $prod-gray !important;
				}
			}
			margin-bottom: 15px;
			border-bottom: 1px solid $prod-gray !important;
			th, td {
				padding: 15px;
				font-size: 14px;
			}
			th {
				font-family: $font-family-bold;
				color: $danger;
			}
			.value-container {
				background-color: $gray;
				border-radius: 10px;
				display: inline-block;
				padding: 7px;
			}
		}
	}
	.input-container {
		display: flex;
		align-items: center;
		.input-white {
			border-radius: 0px !important;
			border: 0px !important;
			border-bottom: 1px solid $black !important;
			font-size: 13px;
			padding: 0px;
		}
		p {
			margin: 0px;
			margin-right: 10px;
			font-size: 13px;
			font-family: $font-family-bold;
		}
	}
	.no-items {
		text-align: center;
		font-family: $font-family-bold;
		color: $prod-gray;
	}
	.col-black {
		padding: 0px;
		background-color: $black2;
		.page-link {
			color: $white !important;
		}
		.btn-reset {
			color: $white !important;
		}
	}
	h3 {
		color: $danger;
		font-family: $font-family-bold;
		margin-bottom: 20px;
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	.input-table {
		@include inputTable();
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.product-container {
		border-top: 1px solid $white;
		max-height: 700px;
		overflow-x: hidden;
		@include scroll();
	}
	.item-product {
		border-bottom: 1px solid $white;
		color: $white;
		font-family: $font-family-medium;
		padding: 10px;
		margin: 0px;
		cursor: pointer;
		padding-left: 20px;
		padding-right: 20px;
		&:hover {
			background-color: lighten($black2,10);
		}
 	}
}