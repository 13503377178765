// Input

textarea.form-control {
	border-radius: 3px;
	background-color: $input !important;
	outline: 0px !important;
	// box-shadow: none !important;
	@include shadow();
	&:focus {
		@include shadow();
	}
	border: 0px !important;
	font-size: 14px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	resize: none;
	&.input-white {
		background-color: $white !important;
	}
}