// Mixins

@mixin shadow() {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
}

@mixin shadow2() {
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}

@mixin shadow3() {
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}

@mixin shadowWhite() {
	box-shadow: 0 3px 6px rgba(255,255,255,0.16), 0 3px 6px rgba(255,255,255,0.23) !important;
}

@mixin scroll() {
	/* width */
	&::-webkit-scrollbar {
	  width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin scrollHorizontal() {
	/* width */
	&::-webkit-scrollbar {
	  height: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin scrollHorizontal() {
	/* width */
	&::-webkit-scrollbar {
	  height: 7px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin scrollHorizontal() {
	/* width */
	&::-webkit-scrollbar {
	  height: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	  background: #f1f1f1; 
	}
	 
	/* Handle */
	&::-webkit-scrollbar-thumb {
	  background: #888; 
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	  background: #555; 
	}
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin placeholderCenter() {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  text-align: center;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  text-align: center;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	  text-align: center;
	}
}

@mixin placeholderBold() {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  font-family: $font-family-bold;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  font-family: $font-family-bold;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
	  font-family: $font-family-bold;
	}
}

@mixin placeholderColor($color) {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $color;
	}

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $color;
	}

	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $color;
	}
}

@mixin noSelect() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@mixin tabs() {
	.nav-pills {
		background-color: $white;
		@include shadow();
		margin-bottom: 20px;
		border-radius: 3px;
		overflow: hidden;
		@media (max-width: 767px) {
			display: block;
		}
		.nav-link {
			border-radius: 0px !important;
			&.active {
				background-color: $prod-green;
			}
			font-family: $font-family-bold;
			font-size: 14px;
			cursor: pointer;
			@include ellipsis();
			&:not(.active) {
				border-right: 1px solid $prod-gray;	
			}
		}
		.nav-item {
			&:last-child {
				.nav-link {
					border-right: 0px !important;
				}
			}
		}
	}
}

@mixin modal() {
	.modal-content {
		border-radius: 10px;
		.modal-body {
			border-bottom-right-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
		}
		.modal-header {
			border-top-right-radius: 10px !important;
			border-top-left-radius: 10px !important;
		}
		@include shadow2();
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 16px;
		}
		.modal-title {
			text-align: center;
			font-size: 20px;
			display: block;
			width: 100%;
		}
	}
}

@mixin tableClients() {
	.td-responsive {
		display: none;
		@media (max-width: 767px) {
			display: table-cell;
		}
	}
	.td-no-responsive {
		@media (max-width: 767px) {
			display: none;
		}
	}
	.table-container-component {
		@include shadow2();		
		border-radius: 10px;
		margin-top: 20px;
		@media (max-width: 767px) {
			box-shadow: none !important;
			border-radius: 0px;
			overflow: hidden;
		}
	}	
	.no-table-data {
		margin: 0px !important;
	}
	table {
		margin-bottom: 0px;
		@media (max-width: 767px) {
			min-width: 100%;
		}
	}
	tbody {
		background-color: $prod-menu-client;
		@media (max-width: 767px) {
			background-color: $white;
			tr {
				border-bottom: 1px solid $black !important;
			}			
		}
		td {
			font-size: 12px;
		}
	}
	.container-title-component {
		display: none;
	}
	thead {
		background-color: $prod-clients-table;
		th {
			text-transform: capitalize;
			font-size: 12px;
			color: $white;
			white-space: nowrap;
		}
		@media (max-width: 767px) {
			&.thead-normal {
				display: none;
			}
			&.thead-responsive {
				display: table-header-group;
			}
		}
	}
}

@mixin ellipsisLines($num: 3) {
	display: -webkit-box;
	-webkit-line-clamp: $num;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin filter() {
	.row-print {
		button {
			margin-top: 20px;
			margin-left: 20px;
			padding-right: 40px;
			padding-left: 40px;
			font-size: 13px;
			font-family: $font-family-bold;
			border: 1px solid $prod-green2 !important;
			&.excel-button {
				color: $prod-green !important;
				background-color: $white;
				border: 1px solid $prod-green !important;
			}
			img {
				width: 20px;
				margin-right: 15px;
			}
		}
	}
	.row-search {
		margin-top: 20px;
		input, select {
			border: 0px !important;
			font-size: 13px;
		}
	}
	.container-datepicker {
		border: 0px !important;
	}
}

@mixin tableWidth() {
	.table-container {
		max-width: 90%;
		margin: 0 auto;
	}
}

@mixin h3Bar() {
	h3 {
		display: inline-block;
		margin: 0 auto;
		color: $prod-green;
		font-size: 14px;
		position: relative;
		margin-left: 110px;
		font-family: $font-family-bold;
		text-transform: capitalize;
		&:before {
			content: '';
			display: block;
			width: 100px;
			height: 1px;
			background-color: $prod-green;
			position: absolute;
			left: -110px;
			top: 7px;
		}
	}
}

@mixin gradient() {
	background: rgb(70,70,72);
	background: linear-gradient(130deg, rgba(70,70,72,1) 0%, rgba(26,26,28,1) 49%, rgba(26,26,28,1) 100%);
}

@mixin selectTable() {
	background-color: $white;
	border: 0px !important;
	padding: 10px !important;
	height: 55px;
	border-radius: 15px !important;
}

@mixin inputTable() {
	input {
		background-color: $white;
		border: 0px !important;
		padding: 10px !important;
		height: 55px;
		border-radius: 15px !important;
	}
	.image-search-input {
		width: 40px;
		top: 7px;
		right: 8px;
	}
}

@mixin datepickerTable() {
	padding: 10px !important;
	border-radius: 15px !important;
	background-color: $white !important;
	border: 0px !important;
	height: 55px;
	img {
		width: 25px;
		height: 25px;
	}
}

@mixin modal() {
	.modal-body {
		background-color: $white;
		border-radius: 20px !important;
		@include shadow2();
		padding: 0px !important;
		padding-top: 30px !important;
	}
	.modal-header {
		background-color: $danger;
		border-radius: 10px !important;
		position: relative;
		top: 30px;
		z-index: 99;
		width: 90%;
		margin: 0 auto;
		button.close {
			display: none;
		}
		.modal-title {
			color: $white;
			font-family: $font-family-bold;
			padding-bottom: 15px;
		}
	}
}