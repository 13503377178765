// Profiles

#profiles {
	padding-bottom: 60px;
	.container-red-component {
		max-width: 700px;
		.select-image {
			width: 300px;
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.item-edit {
		cursor: pointer;
		display: flex;
		.circle {
			background-color: $gray;
			width: 15px;
			height: 15px;
			border-radius: 7.5px;
			border: 1px solid $prod-gray;
			margin-right: 10px;
			margin-top: 4px;
			&.active {
				background-color: $green;
			}
		}
	}
	.title-edit {
		color: $danger;
		font-family: $font-family-bold;
	}
	.container-permissions {
		width: 95%;
		margin: 0 auto;
		max-width: 95%;
	}
	.item-module {
		border-top: 1px solid $black;
		cursor: pointer;
		padding: 0px;
		.title-module {
			display: flex;
			align-items: center;
		}
		&:last-child {
			border-bottom: 1px solid $black
		}
		p {
			margin: 0px;
			margin-top: 20px;
			margin-bottom: 20px;
			@include noSelect();
			padding-left: 10px;
		}
		img {
			width: 30px;
			height: 30px;
			position: absolute;
			right: 15px;
			object-fit: contain;
			@include noSelect();
		}
		.container-actions {
			background-color: $gray;
			padding: 20px;
			p {
				margin: 0px;
			}
		}
	}
}