// Works

#works {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.status-container {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active {
			background-color: $danger;
		}
		&.finish {
			background-color: $green;
		}
	}
}

#create-work {
	padding-bottom: 30px;
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.container-select-search {
		.form-control, .css-1hwfws3 {
			height: 55px;
		}
		.css-yk16xz-control, .css-1pahdxg-control {
			background-color: $gray !important;
			border: 0px !important;
			height: 55px;
			border-radius: 15px !important;
		}
		.image-search-input {
			display: none;
		}
	}
	.container-switch {
		img {
			cursor: pointer;
			width: 50px;
		}
	}
	.no-items {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.item {
		display: flex;
		width: 100%;
		border-bottom: 1px solid $gray;
		padding: 10px;
		p {
			margin: 0px;
			margin-top: 5px;
		}
		.trash {
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}
	.row-add {
		background-color: $gray;
		border-radius: 10px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 10px;
		img {
			width: 35px;
			height: 35px;
			margin-top: 7.5px;
			cursor: pointer;
		}
		.form-group {
			margin-bottom: 0px;
			input {
				padding-left: 0px;
			}
		}
	}
	.container-red-component {
		max-width: 600px;
		padding-left: 20px;
		padding-right: 20px;
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.container-datepicker {
		@include datepickerTable();
		background-color: $danger !important;
		&, .placeholder {
			color: $white;
		}
	}
	label {
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}
	.bold {
		font-size: 16px;
		font-family: $font-family-bold;
		&.bold-switch {
			margin-top: 13px;
		}
	}
	.subtitle {
		font-size: 14px;
	}
	.input-group {
		background-color: $gray;
		border-radius: 10px;
		overflow: hidden;
		height: 50px;
		&.rif-container {
			background-color: $white;
			border-radius: 0px;
			.input-group-prepend {
				width: 60px;
				.form-group {
					width: 100%;
					margin-left: 0px;
				}
				select {
					border-radius: 10px !important;
					height: 50px;
				}
			}
			.form-group {
				width: calc(100% - 70px);
				margin-left: 10px;
				input {
					border-radius: 10px !important;
				}
			}
		}
		.input-group-prepend {
			height: 50px;
			width: 50px;
		}
		.form-group {
			width: calc(100% - 50px)
		}
		input {
			border-radius: 0px !important;
		}
		img {
			width: 40px;
			height: 40px;
			margin-top: 5px;
			margin-left: 5px;
		}
	}
}

.modal-view-work {
	@include modal();
	.modal-dialog {
		max-width: 700px;
	}
	.modal-title {
		margin-top: 15px;
		margin-left: 20px;
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 18px;
			font-family: $font-family-bold;
			margin-top: 15px;
			margin-left: 10px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
		.link-icon {
			display: inline-block;
			margin-top: 10px;
			margin-right: 0px;
		}
	}
}

#modal-view-work {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 30px;
	button[type="button"] {
		background-color: transparent !important;
		color: $danger !important;
		font-family: $font-family-bold;
		font-size: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.work-title {
		display: flex;
		img {
			width: 25px;
			height: 25px;
			margin-left: 10px;
		}
	}
	.canceled-title {
		display: flex;
		margin-top: 5px;
		margin-bottom: 5px;
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
			margin-top: 1px;
		}
	}
	.title {
		margin: 0px;
		font-size: 16px;
		margin-bottom: 5px;
	}
	.bold-red {
		color: $danger;
		font-family: $font-family-bold;
	}
	.init-work {
		font-size: 14px;
		margin: 0px;
		margin-bottom: 5px;
	}
	.row-date {
		max-width: 400px;
	}
	.item-date {
		display: flex;
		img {
			margin-right: 10px;
			width: 25px;
			height: 25px;
		}
		p {
			margin: 0px;
			font-size: 14px;
			margin-top: 3px;
		}
	}
	.bold {
		font-family: $font-family-bold;
	}
	.description {
		font-size: 14px;
	}
	.item {
		display: flex;
		padding: 5px;
		border-bottom: 1px solid $gray;
		img {
			width: 30px;
			height: 30px;
			margin-top: 5px;
		}
		p {
			margin: 0px;
			margin-top: 5px;
		}
	}
}