// Home

#home {
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 50px;
	.item-report-container {
		.item-report {
			@include shadow();
			border-radius: 10px;
			padding: 20px;
			background-color: $white;
			position: relative;
			margin-top: 30px;
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			p {
				width: 50%;
				text-align: right;
				max-width: 130px;
				font-family: $font-family-bold;
			}
			.image-container {
				border-radius: 10px;
				padding: 10px;
				display: inline-block;
				position: absolute;
				top: -20px;
				left: 10px;
				img {
					width: 40px;
					height: 40px;
				}
			}
		}
		&:nth-child(1) > .item-report {
			.image-container {
				background-color: $_item_reporte1;
			}
		}
		&:nth-child(2) > .item-report {
			.image-container {
				background-color: $_item_reporte2;
			}
		}
		&:nth-child(3) > .item-report {
			.image-container {
				background-color: $_item_reporte3;
			}
		}
		&:nth-child(4) > .item-report {
			.image-container {
				background-color: $_item_reporte4;
			}
		}
	}
}

@mixin homeModules() {
	background-color: $white;
	@include shadow();
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 15px;
	margin-top: 15px;
	.title-home {
		h3 {
			font-family: $font-family-bold;
			font-size: 16px;
		}
		display: flex;
		.count-container {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			p {
				font-size: 13px;
				margin: 0px;
			}
			img {
				width: 15px;
				height: 15px;
				margin-right: 10px;
				object-fit: contain;
			}
		}
		.add-item {
			display: inline-flex;
			cursor: pointer;
			margin-top: 10px;
			margin-right: 10px;
			@include noSelect();
			img {
				width: 23px;
				height: 23px;
			}
			p {
				margin: 0px;
				font-family: $font-family-bold;
				margin-left: 10px;
				color: $danger;
			}
		}
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		margin-top: 0px;
		border-radius: 0px;
		box-shadow: none !important;
	}
	.no-table-data {
		font-size: 14px;
		margin-top: 10px;
	}
	.view-more {
		text-align: center;
		color: $danger !important;
		text-decoration: underline !important;
		font-family: $font-family-bold;
		font-size: 15px;
	}
}

#home-works {
	@include homeModules();
}

#home-products {
	@include homeModules();
	.image-category {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		object-fit: cover;
	}
}

#home-orders {
	@include homeModules();
	margin-top: 30px;
	.deleted-item {
		td {
			font-style: italic;
			color: lighten($prod-gray,20);
		}
	}
	.code {
		color: $danger;
		font-family: $font-family-bold;
	}
}