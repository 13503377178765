// Plans

#plans {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.name {
		font-family: $font-family-medium;
		color: $danger;
	}
	.change-status {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active {
			background-color: $green;
		}
		&.inactive {
			background-color: $danger;
		}
	}
}

#create-plans {
	width: 90%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-select-search {
		.form-control, .css-1hwfws3 {
			height: 50px;
			border-bottom: 1px solid #000 !important;
			border-radius: 0px !important;
		}
		.css-yk16xz-control, .css-1pahdxg-control {
			background-color: transparent !important;
			border: 0px !important;
			height: 50px;
			border-radius: 0px !important;
		}
		.image-search-input {
			display: none;
		}
	}
	.item-check {
		display: flex;
		margin-bottom: 20px;
		cursor: pointer;
		p {
			margin: 0px;
			font-size: 14px;
		}
		.circle {
			margin-right: 5px;
			width: 15px;
			height: 15px;
			border: 1px solid #000;
			background-color: #e0e0e0;
			border-radius: 7.5px;
			position: relative;
			top: 3px;
			&.active {
				background-color: $danger;
			}
		}
	}
	.container-form {
		background-color: $white;
		border-radius: 10px;
		padding: 20px;
		margin-bottom: 20px;
	}
	.table-bottom {
		position: relative;
		.container-title-component {
			background-color: transparent;
			width: 100%;
			margin: 0px;
			padding: 0px;
			top: 50px;
			margin-left: 20px;
			.title-component {
				color: $danger;
				font-size: 25px;
			}
		}
		.table-container-component, .table-container {
			box-shadow: none !important;
			border-radius: 10px;
			margin-top: 0px;
			padding-top: 70px;
		}	
		thead tr {
			border-bottom-width: .5px !important;
		}
	}
	select:not(.input-top) {
		@include selectTable();
		background-color: transparent;
		border-radius: 0px !important;
		border-bottom: 1px solid #000 !important;
		height: 50px;
	}
	select.input-top {
		@include selectTable();
		background-color: $gray;
		height: 50px;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	.form-group:not(.input-top) input {
		background-color: transparent;
		border-radius: 0px !important;
		border: 0px !important;
		border-bottom: 1px solid #000 !important;
		height: 50px;
	}
	.form-group.input-top input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.container-datepicker {
		@include datepickerTable();
		background-color: $danger !important;
		&, .placeholder {
			color: $white;
		}
	}
	button.btn-submit {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.view-preview {
		background-color: transparent !important;
		color: $danger !important;
		font-family: $font-family-bold;
		font-size: 20px;
		position: absolute;
		right: 10px;
		top: 40px;
		z-index: 999;
	}
	.view-requisition {
		font-family: $font-family-bold;
		font-size: 12px;
		cursor: pointer;
		color: $danger;
		margin: 0px;
		margin-bottom: 20px;
	}
}

.modal-generate-requisition {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 10px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 16px;
			font-family: $font-family-bold;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-left: 20px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
	}
}

#modal-generate-requisition {
	padding: 15px;
	.btn-submit {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		background-color: transparent !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		font-family: $font-family-bold;
		color: $danger !important;
	}
	.container-datepicker {
		@include datepickerTable();
		background-color: $danger !important;
		&, .placeholder {
			color: $white;
		}
	}
}

.modal-view-plan {
	@include modal();
	.modal-dialog {
		max-width: 1200px;
	}
}

#modal-view-plan {
	padding: 15px;
	.btn-close {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		background-color: transparent !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		font-family: $font-family-bold;
		color: $danger !important;
	}
	.form-group:not(.input-top) input {
		background-color: transparent;
		border-radius: 0px !important;
		border: 0px !important;
		border-bottom: 1px solid #000 !important;
		height: 50px;
	}
	.form-group.input-top input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.container-form {
		background-color: $white;
		border-radius: 10px;
		padding: 20px;
		padding-bottom: 0px;
	}
	.table-bottom {
		position: relative;
		.container-title-component {
			background-color: transparent;
			width: 100%;
			margin: 0px;
			padding: 0px;
			top: 30px;
			margin-left: 20px;
			.title-component {
				color: $danger;
				font-size: 20px;
			}
		}
		.table-container-component, .table-container {
			box-shadow: none !important;
			border-radius: 10px;
			margin-top: 0px;
			padding-top: 70px;
		}	
		thead tr {
			border-bottom-width: .5px !important;
		}
	}
}