// Base

* {
	font-family: $font-family;
}

body {
	overflow-x: hidden;
	background-color: $gray;
}

.icon-view-photo {
	margin-right: 5px;
	img {
		width: 20px;
	}
}

.react-datepicker-popper {
	z-index: 9999;
}

.container-menu {
	display: flex;
	flex-direction: row;
	padding-left: $width-menu;
	transition: .6s;
	background-color: $gray;
	.arrow-drop {
		width: 15px;
		heigth: 15px;
		position: absolute;
		right: 15px;
		top: 20px;
	}
	@media (max-width: 767px) {
		padding-left: 0px !important;
	}
	.container-icon-menu {
		background-color: $white;
		border-radius: 10px;
		padding: 5px;
		width: 35px;
		height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include shadowWhite();
		margin-right: 15px;
		margin-left: 10px;
		&.active {
			background-color: $danger;
			@include shadow2();
		}
		img {
			width: 23px;
			height: 23px;
			object-fit: contain;
		}
	}
}

.forbidden {
	margin: 20px;
	font-size: 14px;
	text-align: center;
}

.navbar-toggler {
	border: 0px !important;
	outline: 0px !important;
	height: 54px;
	i.fa {
		color: $black;
		font-size: 30px;
	}
}

.navbar {
	z-index: 998;
	background-color: $gray !important;
	@media (min-width: 768px) {
		display: none;	
	}
	.navbar-collapse {
		a {
			color: $white !important;
		}
	}
	.item-submenu {
		width: 90%;
		margin-left: 10%;
		background-color: transparent;
	}
	.item-submenu.active {
		a {
			text-decoration: underline !important;
		}				
	}
	.nav-link {
		padding: 10px;
	}
	.nav-item.active {
		background-color: $prod-green2;
	}
}
.modal.high-modal {
	.modal-dialog {
		max-width: 80% !important;
		@media (max-width: 600px) {
			max-width: 100% !important;
		}
	}
}
.nav-item {
	.badge {
		background-color: $prod-green;
		margin-left: 10px;
		border-radius: 9px;
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: $font-family-bold;
		color: $white;
	}
	&.active {
		.badge {
			background-color: $white !important;
			color: $prod-green !important;
		}
	}
	&.is-logout {
		margin-top: 60px;
	}
}

#left {
	.toggle-menu {
		position: fixed;
		bottom: 10px;
		left: 5px;
		z-index: 99999;
		cursor: pointer;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
		transition: 1s;
		@media (max-width: 767px) {
			display: none;
		}
		i.fa {
			font-size: 28px;
			color: $prod-green;
		}
	}
	&.closed {
		.toggle-menu {
			left: 0px;
		}
		.menu {
			ul {
				margin-top: 53px;
			}
		}		
	}	
}

.menu {
	.bg-orange {
		min-height: 100vh;
		width: 60px;
		position: absolute;
		left: 0px;
		top: 0px;
	}
	.container-logo {
		text-align: center;
		&:after {
			content: '';
			width: 90%;
			margin: 0 auto;
			background-color: $white;
			height: 1px;
			display: block;
			margin-bottom: 20px;
		}
		// padding-top: 30px;
		// padding-bottom: 30px;
		&.closed {
			display: none;	
		}
		img {
			width: 100px;
			margin: 0 auto;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		p {
			font-size: 12px;
			margin-left: 80px;
			color: $black;
			margin-top: 17.5px;
			font-family: $font-family-bold;
			text-transform: uppercase;
		}
	}
	position: fixed;
	left: 0;
	top: 0;
	min-height: 95vh;
	height: 95%;
	margin-top: 2.5vh;
	margin-bottom: 0px !important;
	@include gradient();
	border-radius: 10px;
	overflow: hidden;
	width: $width-menu - 20px;
	margin-left: 20px;
	transition: .6s;
	z-index: 1000;
	.container-user {
		display: flex;
		align-items: center;
		margin-top: 50px;
		margin-bottom: 20px;
		margin-left: 15px;
		width: 80%;
		img {
			width: 50px;
			height: 50px;
			border-radius: 100px;
			margin-right: 20px;
			object-fit: cover;
		}
		h2 {
			color: $white;
			font-family: $font-family-bold;
			font-size: 16px;
			margin-top: 10px;
		}
	}
	.btn-logout {
		color: $white !important;
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 20px;
		box-shadow: none !important;
		border: 0px !important;
		outline: 0px !important;
	}
	.container-submenu {
		
	}
	ul {
		padding: 0px !important;
		margin-bottom: 50px;
		list-style: none;
		&.closed {
			li {
				padding-left: 75%;
			}
		}
		li {
			@include ellipsis();
			transition: .6s;
			min-height: $height-item;
			&.active {
				// background-color: $prod-green;
				a {
					color: $white !important;
				}
			}
			&.item-submenu {
				white-space: normal !important;
				border: 0px !important;
				&:last-child {
					// padding-bottom: 10px;
				}
				&.active {
					a {
						background-color: $danger !important;
					}
				}
				a {
					padding-left: 0px !important;
					font-size: 15px;
					color: $white !important;
					margin-bottom: 0px;
					background-color: $prod-gray !important;
					padding-top: 10px !important;
					padding-bottom: 10px !important;
					img {
						width: 30px;
						height: 30px;
						margin-left: 20px;
						margin-right: 15px;
					}
				}
			}
			span.closed {
				display: none;
			}
			.submenu {
				font-size: 12px;
				padding: 5px !important;
				padding-left: 60px !important;
			}
			a {
				position: relative;
				display: flex;
				align-items: center;
				color: $white !important;
				text-decoration: none !important;
				padding: 7px !important;
				font-size: 16px;
				white-space: pre-wrap;
				margin-bottom: 15px;
				z-index: 1;
				.fa-chevron-up {
					margin-top: 8px;
					margin-right: 5px;
					font-size: 14px;
				}
				.badge {
					background-color: $prod-green;
					margin-left: 10px;
					border-radius: 30px;
				}
				i {
					position: absolute;
					right: 10px;
				}
			}
		}
	}
}

.container-router {
	width: 100%;
	height: 100vh;
	padding-top: 90px;
	background-color: $gray;
	@media (max-width: 767px) {
		padding-top: 50px;
	}
	.container-padding-router {
		max-width: 100vw;
		&.container-responsive {
			@media (max-width: 767px) {
				padding-top: 94px;
			}
		}
	}
}

.ver-todo {
	color: $prod-green;
	display: inline-block;
	cursor: pointer;
	font-family: $font-family-bold;
	margin-top: 0px !important;
}

.btn-profile {
	margin-right: 40px;
}

.fa.danger {
	color: $danger;
}

.fa.success {
	color: $green;
}

.react-viewer {
	position: relative;
	z-index: 9999;
}

.input-file-paperclip {
	display: none;
}

.container-paper-clip {
	button {
		min-width: 150px;
	}
	.fa {
		margin-right: 5px;
	}
}

.container-custom-header-datepicker {
	margin: 10px;
    display: flex;
    justify-content: center;
    button {
    	background-color: transparent !important;
    	border: 0px !important;
    }
    select {
    	margin-right: 5px;
    	margin-left: 5px;
    	border: 0px !important;
    	border-radius: 5px;
    	outline: 0px !important;
    }
}

.btn-blue {
	width: 150px;
	background-color: $prod-green2 !important;
	@media (max-width: 991px) {
		margin-bottom: 20px;
	}
}

.link-icon {
	margin-right: 10px;
	width: 30px;
	heigth: 30px;
	position: relative;
	img {
		width: 30px;
		heigth: 30px;
	}
	i.fa {
		font-size: 20px;
		color: $prod-blue2 !important;
	}
	&.link-red {
		i.fa {
			color: $danger !important;
		}		
	}
	.badge {
		width: 15px;
		height: 15px;
		border-radius: 7.5px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $danger;
		color: $white;
		font-family: $font-family-bold;
		position: absolute;
		top: -10px;
		right: -5px;
	}
}

.row-filter {
	.btn-blue {
		margin-top: 32.5px;
	}
}

.btn-reset {
	margin: 0px;
	font-size: 12px;
	cursor: pointer;
	font-family: $font-family-bold;
	display: inline-block;
}

.icon-actions {
	min-width: 120px;
}

.container-loading-top {
	margin-top: 30px;
}

footer {
	height: $footer-height;
	p {
		text-align: center;
		background-color: $prod-gray;
		padding: 10px;
		font-size: 12px;
		color: $black;
		a {
			color: $black !important;
		}
	}
}

.btn-primary.btn-blue-create {
	background-color: $prod-green3 !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
}

.status-color {
	font-family: $font-family-bold;
}