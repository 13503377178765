// Reports

#reports {
	padding: 50px;
	.item-report-container {
		.item-report {
			@include shadow();
			border-radius: 10px;
			height: 100px;
			padding: 20px;
			background-color: $white;
			position: relative;
			margin-top: 30px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			cursor: pointer;
			@media (max-width: 1400px) {
				p {
					margin-top: 30px;
				}
			}
			p {
				width: 130px;
				text-align: right;
				font-size: 14px;
				font-family: $font-family-bold;
				margin: 0px;
			}
			.image-container {
				border-radius: 10px;
				padding: 10px;
				display: inline-block;
				position: absolute;
				top: -20px;
				left: 10px;
				img {
					width: 40px;
					height: 40px;
				}
			}
		}
	}
}

#reports-control-bar {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.download-button-component {
		display: inline-flex;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-product-requisitions {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
	.status-container {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active, &.cancel {
			background-color: $danger;
		}
		&.finish {
			background-color: $green;
		}
	}
}

#reports-cristal-inventory {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.download-button-component {
		display: inline-flex;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

.modal-view-product-requisition {
	@include modal();
	.modal-dialog {
		max-width: 500px;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 20px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 15px;
			font-family: $font-family-bold;
			margin-top: 15px;
			margin-left: 10px;
		}
		p {
			color: $white;
			margin-left: 10px;
			font-size: 15px;
		}
	}
}

#modal-view-product-requisition {
	padding: 20px;
	table {
		tr {
			border-top: 1px solid $black;
			&:last-child {
				border-bottom: 1px solid $black;
			}
			th, td {
				font-size: 14px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	.status-container {
		@include shadow();
		border-radius: 30px;
		padding: 8px;
		padding-right: 20px;
		padding-left: 20px;
		p {
			margin: 0px;
			text-align: center;
			color: $white;
		}
		&.active, &.cancel {
			background-color: $danger;
		}
		&.finish {
			background-color: $green;
		}
	}
	.level-container {
		font-size: 14px;
		font-family: $font-family-bold;
		margin-bottom: 5px;
	}
	.calendar-container {
		.calendar {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
		font-size: 14px;
	}
}

#reports-delivery {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			font-size: 14px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.payment-utilities {
		margin: 0px;
		font-size: 12px;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-control-payments {
	// max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			font-size: 14px;
			margin-bottom: 5px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 10px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 10px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-control-out {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			font-size: 14px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-host-errors {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			font-size: 14px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-reception {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			font-size: 14px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	.download-button-component {
		display: inline-flex;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	tr.danger {
		background-color: lighten($danger,50);
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-add-clients {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			font-size: 14px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-works {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.container-total {
		background-color: $white;
		border-radius: 10px;
		padding: 15px;
		@include shadow();
		display: inline-block;
		p {
			margin: 0px;
			margin-bottom: 5px;
			font-size: 14px;
			strong {
				font-family: $font-family-bold;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-checklist {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.item-red {
		color: $danger;
		font-family: $font-family-bold;
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-portioning {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.name {
		p {
			margin: 0px;
			font-size: 12px;
			font-family: $font-family-bold;
		}
		span {
			font-size: 12px;
		}
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 12px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 12px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-loss {
	max-width: 1300px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 12px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 12px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-operations {
	max-width: 1300px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.lote-numbers {
		font-size: 12px;
		margin-bottom: 3px;
	}
	.name {
		p {
			margin: 0px;
			font-size: 12px;
			font-family: $font-family-bold;
		}
		span {
			font-size: 11px;
			display: block;
			&.code {
				font-family: $font-family-bold;
				color: $danger;
			}
		}
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 12px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 12px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-decrease {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.download-button-component {
		display: inline-flex;
	}
	.lote-numbers {
		font-size: 12px;
		margin-bottom: 3px;
	}
	.tabs-container {
		display: flex;
		.tab {
			background-color: $white;
			border-radius: 10px;
			padding: 10px;
			@include shadow();
			width: 95%;
			margin: 0 auto;
			text-align: center;
			font-family: $font-family-bold;
			margin-top: 15px;
			cursor: pointer;
			&.active {
				background-color: $danger;
				color: $white;
			}
		}
	}
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 12px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 12px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

#reports-compliance {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 12px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 12px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

.modal-view-works {
	@include modal();
	.modal-dialog {
		max-width: 1000px;
	}
	.modal-body {
		background-color: #f2f2f2;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 10px;
			margin-bottom: 10px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 15px;
			font-family: $font-family-bold;
			margin-top: 20px;
			margin-left: 10px;
		}
	}
}

#modal-view-works {
	padding: 20px;
	select {
		@include selectTable();
	}
	.input-table {
		@include inputTable();
	}
	.container-datepicker {
		@include datepickerTable();
	}
	.container-title-component {
		display: none;
	}
	li {
		font-size: 12px;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}

.modal-view-recipe {
	@include modal();
	.modal-dialog {
		max-width: 1000px;
	}
	.modal-body {
		background-color: #f2f2f2;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 10px;
			margin-bottom: 10px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 15px;
			font-family: $font-family-bold;
			margin-top: 20px;
			margin-left: 10px;
		}
	}
}

#modal-view-recipe {
	padding: 20px;
	.container-title-component {
		display: none;
	}
	li {
		font-size: 12px;
	}
	.table-container-component {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		border-radius: 10px;
		margin-top: 0px;
	}
	thead {
		background-color: $default2;
		th {
			font-size: 14px;
			color: $white;
			&:first-child {
				padding-left: 20px !important;
			}
			&:last-child {
				padding-left: 20px !important;
			}
		}
	}
	td {
		font-size: 14px;
		color: $black;
		font-family: $font-family-medium;
		&:first-child {
			padding-left: 20px !important;
		}
		&:last-child {
			padding-left: 20px !important;
		}
	}
	.no-table-data {
		margin-top: 10px;
	}
}