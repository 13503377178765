// Container Red

.container-red-component {
	max-width: 95%;
	margin: 0 auto;
	.container-white {
		background-color: $white;
		@include shadow2();
		border-radius: 20px;
		padding: 20px;
		padding-top: 50px;
	}
	.title-red {
		background-color: $danger;
		border-radius: 10px;
		width: 90%;
		margin: 0 auto;
		padding: 20px;
		position: relative;
		top: 30px;
		z-index: 99;
		p {
			margin: 0px;
			color: $white;
			font-family: $font-family-bold;
		}
	}
}