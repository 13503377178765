// Login

#login {
	.container-bg {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right center;
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		flex-direction: column;
		transition: 1s;
		@media (max-width: 991px) {
			display: none;
		}
		h3 {
			font-size: 60px;
			color: $white;
			font-family: $font-family-bold;
			max-width: 420px;
			margin-left: 20%;
		}
		h4 {
			color: $prod-gray;
			font-size: 22px;
			max-width: 420px;
			margin-left: 20%;
			margin-top: 15px;
			letter-spacing: 1px;
			line-height: 35px;
		}
	}	
	.row {
		margin: 0px !important;
	}
	.container-form {
		min-height: 100vh;
	}
	.password-view {
		cursor: pointer;
	}
	height: 100vh;
	.container-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		background-color: $prod-green;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		border-radius: 200px;
		position: relative;
		top: -70px;
		margin: 0 auto;
		margin-bottom: -50px;
	}
	.recover {
		margin-top: 20px;
		text-align: center;
		margin-bottom: 0px;
		font-size: 18px;
		a {
			font-family: $font-family-bold;
			color: $black !important;
		}
	}
	.arrow-green {
		position: absolute;
		left: -100px;
		top: 20px;
		width: 500px;
		object-fit: contain;
		z-index: 0;
	}
	form {
		background-color: $white;
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.container-logo {
			padding: 20px;
			padding-top: 0px;
			text-align: center;
			img {
				width: 100%;
				max-width: 170px;
				margin: 0 auto;
			}
		}
		.input-group {
			label {
				color: $prod-gray;
				font-size: 14px;
			}
			.input-group-prepend {
				img {
					width: 30px;
					height: 30px;
					object-fit: contain;
					margin-top: 5px;
				}
			}
			input {
				border: 0px !important;
				font-size: 14px;
				border-radius: 0px !important;
				background-color: $gray !important;
			}
			.form-group {
				margin: 0px;
				width: 90%;
			}
			background-color: $gray;
			border-radius: 0px;
			margin-bottom: 15px;
			padding: 5px;
			border-bottom: 1px solid $black;
		}
		.container-input {
			padding: 20px;
			padding-left: 30px;
			padding-right: 30px;
			width: 95%;
			border-radius: 10px;
			margin: 0 auto;
			margin-top: 20px;
		}
		input {
			font-size: 13px;
		}
		button {
			border-radius: 10px !important;
			width: 300px;
			font-size: 16px;
			margin: 0 auto;
			margin-top: 10px;
			background-color: $danger !important;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			@include shadow3();
			font-family: $font-family-bold;
			padding-top: 15px !important;
			padding-bottom: 15px !important;
		}
	}
}