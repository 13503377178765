// Host

#host {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.input-table {
		@include inputTable();
	}
}

.modal-create-board {
	@include modal();
	.modal-dialog {
		max-width: 600px;
	}
}

#modal-create-board {
	.content-create {
		padding: 20px;
	}
	.row-buttons {
		&, .col-6 {
			padding: 0px !important;
			margin: 0px !important;
		}
		button {
			width: 100%;
			border-radius: 0px !important;
			&.btn-close {
				background-color: $gray !important;
				padding: 15px !important;
				border-bottom-left-radius: 10px !important;
				color: $black !important;
			}
			&.btn-save {
				background-color: $danger !important;
				padding: 15px !important;
				border-bottom-right-radius: 10px !important;
			}
		}
	}
	input, select {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
}

.modal-create-area {
	@include modal();
	.modal-dialog {
		max-width: 600px;
	}
}

#modal-create-area {
	.content-create {
		padding: 20px;
	}
	.row-buttons {
		&, .col-6 {
			padding: 0px !important;
			margin: 0px !important;
		}
		button {
			width: 100%;
			border-radius: 0px !important;
			&.btn-close {
				background-color: $gray !important;
				padding: 15px !important;
				border-bottom-left-radius: 10px !important;
				color: $black !important;
			}
			&.btn-save {
				background-color: $danger !important;
				padding: 15px !important;
				border-bottom-right-radius: 10px !important;
			}
		}
	}
	input, select {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
}