// Header

#header {
	background-color: $gray;
	width: 100%;
	align-items: center;
	position: fixed;
	left: 0px;
	.container-back {
		background-color: $prod-gray;
		height: $height-item;
		text-align: center;
		display: flex;
		align-items: center;
		p {
			text-transform: uppercase;
			width: 100%;
			img {
				width: 20px;
				height: 20px;
			}
		}
	}
	.container-titles {
		width: calc(100% - 300px);
		@media (max-width: 991px) {
			width: 100%;
		}
	}
	.container-back-top {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.container-blue-top {
		background-color: $gray;
		height: $height-banner;
		padding: 12px;
		padding-left: 25px;
		padding-right: 25px;
		display: flex;
		align-items: center;
		h3, h4 {
			color: $black;
			font-size: 22px;
			font-family: $font-family-bold;
			margin: 0px;
			padding: 0px;
			@media (max-width: 767px) {
				width: 100%;
			}
			@media (max-width: 991px) {
				font-size: 18px;
			}
		}
		h4 {
			font-size: 18px;
			color: $danger;
		}
		.container-user {
			@media (max-width: 600px) {
				p {
					display: none;
				}				
			}
		}
		p {
			font-family: $font-family;
			font-size: 12px;
			margin-bottom: 3px;
			color: $white;
		}
	}
	&.header-responsive {
		@media (max-width: 767px) {
			margin-top: 54px;
		}		
	}
	@media (min-width: 768px) {
		padding-left: $width-menu;
	}
	z-index: 998;
	transition: .6s;
	top: 0px;
	&.closed {
		@media (min-width: 768px) {
			padding-left: 50px;
		}
	}
	p {
		margin-bottom: 0px;
		font-size: 14px;
		font-family: $font-family-bold;
		@include ellipsis();
		width: 90%;
		position: relative;
	}
	.arrow-back {
		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
			margin-right: 15px;
			cursor: pointer;
		}
	}
	.focusable-container {
		box-shadow: none !important;
		outline: 0px !important;
		border: 0px !important;
		background-color: transparent !important;
		padding: 0px !important;
	}
	ul {
		position: absolute;
		background-color: $white;
		min-width: 250px;
		right: 10px;
		top: 40px;
		list-style: none;
		padding: 0px;
		@include shadow();
		z-index: 999;
		border-radius: 10px;
		li {
			text-align: left;
			cursor: pointer;
			font-size: 16px;
			padding: 5px;
			padding-left: 15px;
			padding-right: 15px;
			img {
				width: 25px;
				height: 25px;
				margin-right: 15px;
			}
			&:hover {
				background-color: $prod-gray2;
			}
		}
	}
	.notification-icon {
		width: 30px;
		height: 30px;
		margin-top: 5px;
		margin-right: 10px;
		margin-left: 10px;
	}
	.container-user {
		display: flex;
		cursor: pointer;
		margin-right: 15px;
		border: 2px solid $black;
		border-radius: 30px;
		justify-content: center;
		align-items: center;
		padding-top: 5px;
		padding-bottom: 5px;
		img {
			width: 30px;
			height: 30px;
			margin-left: 10px;
		}
		i.fa {
			font-size: 25px;
			margin-right: 10px;
			margin-left: 15px;
		}
		p {
			padding: 0px;
			margin: 0px;
			margin-left: 10px;
			color: $black;
			font-size: 16px;
			font-family: $font-family-bold;
		}
	}
	.container-buttons-top {
		display: flex;
		justify-content: space-between;
		position: absolute;
		right: 15px;
		@media (max-width: 767px) {
			top: -40px;
		}
	}
}