// Checklist

#checklist {
	max-width: 1000px;
	width: 95%;
	margin: 0 auto;
	padding-bottom: 40px;
	.row-buttons {
		margin-top: 10px;
	}
	.input-table {
		@include inputTable();
	}
	.item-red {
		color: $danger;
		font-family: $font-family-bold;
	}
}

#create-checklist {
	padding-bottom: 30px;
	button[type="submit"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 17px !important;
		padding-bottom: 17px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.container-switch {
		img {
			cursor: pointer;
			width: 50px;
		}
	}
	.no-items {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.item {
		display: flex;
		width: 100%;
		border-bottom: 1px solid $gray;
		padding: 10px;
		p {
			margin: 0px;
			margin-top: 5px;
		}
		.trash {
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}
	.row-add {
		background-color: $gray;
		border-radius: 10px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 10px;
		img {
			width: 35px;
			height: 35px;
			margin-top: 7.5px;
			cursor: pointer;
		}
		.form-group {
			margin-bottom: 0px;
			input {
				padding-left: 0px;
			}
		}
	}
	.container-select-search {
		.form-control, .css-1hwfws3 {
			height: 55px;
		}
		.css-yk16xz-control, .css-1pahdxg-control {
			background-color: $gray !important;
			border: 0px !important;
			height: 55px;
			border-radius: 15px !important;
		}
		.image-search-input {
			display: none;
		}
	}
	.container-red-component {
		max-width: 900px;
		padding-left: 20px;
		padding-right: 20px;
	}
	select {
		@include selectTable();
		background-color: $gray;
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	input {
		background-color: $gray !important;
		border-radius: 10px !important;
		border: 0px !important;
		height: 50px;
	}
	.container-datepicker {
		@include datepickerTable();
		background-color: $danger !important;
		&, .placeholder {
			color: $white;
		}
	}
	label {
		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}
	.bold {
		font-size: 16px;
		font-family: $font-family-bold;
		&.bold-switch {
			margin-top: 13px;
		}
	}
	.subtitle {
		font-size: 14px;
	}
	.input-group {
		background-color: $gray;
		border-radius: 10px;
		overflow: hidden;
		height: 50px;
		&.rif-container {
			background-color: $white;
			border-radius: 0px;
			.input-group-prepend {
				width: 60px;
				.form-group {
					width: 100%;
					margin-left: 0px;
				}
				select {
					border-radius: 10px !important;
					height: 50px;
				}
			}
			.form-group {
				width: calc(100% - 70px);
				margin-left: 10px;
				input {
					border-radius: 10px !important;
				}
			}
		}
		.input-group-prepend {
			height: 50px;
			width: 50px;
		}
		.form-group {
			width: calc(100% - 50px)
		}
		input {
			border-radius: 0px !important;
		}
		img {
			width: 40px;
			height: 40px;
			margin-top: 5px;
			margin-left: 5px;
		}
	}
	.selected-days {
		background-color: $danger;
		border-radius: 15px;
		display: flex;
		padding: 13px;
		cursor: pointer;
		img {
			width: 30px;
			height: 30px;
			margin-right: 10px;
		}
		p {
			font-size: 14px;
			color: $white;
			margin: 0px;
			margin-top: 5px;
		}
	}
}

.modal-checklist-days {
	@include modal();
	.modal-dialog {
		max-width: 370px;
	}
}

#modal-checklist-days {
	padding: 20px;
	button[type="button"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 14px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.item-day {
		border-bottom: 1px solid $prod-gray2;
		cursor: pointer;
		padding-top: 7px;
		padding-bottom: 7px;
		display: flex;
		p {
			margin: 0px;
			margin-top: 3px;
		}
		.item-active {
			background-color: $green;
			width: 20px;
			height: 20px;
			border-radius: 10px;
			display: inline-block;
			margin-top: 5px;
		}
		.item-inactive {
			background-color: $prod-gray2;
			width: 20px;
			height: 20px;
			border-radius: 10px;
			display: inline-block;
			margin-top: 5px;
		}
	}
}

.modal-view-checklist {
	@include modal();
	.modal-dialog {
		max-width: 400px;
	}
}

#modal-view-checklist {
	padding: 15px;
	button[type="button"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: transparent !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		font-family: $font-family-bold;
		color: $danger !important;
	}
	.title {
		font-size: 14px;
		margin: 0px;
		margin-bottom: 5px;
	}
	.date {
		 display: flex;
		 margin-top: 10px;
		 margin-bottom: 10px;
		 p {
		 	margin: 0px;
		 	font-size: 14px;
		 }
		 img {
		 	width: 20px;
		 	height: 20px;
		 	margin-right: 10px;
		 }
	}
	.date-container {
		display: flex;
	}
	.item {
		margin-bottom: 0px;
		padding: 10px;
		font-size: 14px;
		border-bottom: 1px solid $prod-gray3;
	}
	.item-checklist-details {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.item-checklist-details-icon {
		width: 20px;
		height: 20px;
	}
}

.modal-checklist-users {
	@include modal();
	.modal-dialog {
		max-width: 400px;
	}
	.row-modal-title {
		width: 100%;
		.close-modal-view {
			font-size: 25px;
			color: $white !important;
			margin-top: 10px;
			display: inline-block;
		}
	}
	.modal-header {
		padding: 0px !important;
		h3 {
			color: $white;
			font-size: 16px;
			font-family: $font-family-bold;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-left: 20px;
		}
		p {
			color: $white;
			margin-left: 10px;
		}
	}
}

#modal-checklist-users {
	padding: 20px;
	button[type="button"] {
		border-radius: 10px !important;
		width: 200px;
		font-size: 14px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 20px;
		background-color: $danger !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		@include shadow3();
		font-family: $font-family-bold;
	}
	.container-select-search {
		.form-control, .css-1hwfws3 {
			height: 55px;
		}
		.css-yk16xz-control, .css-1pahdxg-control {
			background-color: $gray !important;
			border: 0px !important;
			height: 55px;
			border-radius: 15px !important;
		}
		.image-search-input {
			display: none;
		}
	}
	.no-items {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.item {
		display: flex;
		width: 100%;
		border-bottom: 1px solid $gray;
		padding: 10px;
		p {
			margin: 0px;
			margin-top: 5px;
		}
		.trash {
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}
}