// Buttons modal

.buttons-modal-component {
	display: flex;
	button {
		width: 100%;
		border-radius: 0px !important;
		font-family: $font-family-bold;
		font-size: 18px;
		padding-top: 15px;
		padding-bottom: 15px;
		&[type="submit"] {
			background-color: $danger !important;
			color: $white !important;
			border-bottom-right-radius: 20px !important;
		}
		&[type="button"] {
			background-color: $gray !important;
			color: $black !important;
			border-bottom-left-radius: 20px !important;
		}
	}
}